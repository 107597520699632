.App {}

.logo-strip.yellow {
  background: orange;
}

.card-body {
  border-left: white solid 2px;
  border-right: white solid 2px;
}

.card-body.yellow {
  background: yellow;
}

.logo-strip.blue {
  background: blue;
}

.card-body.blue {
  background: lightskyblue;
}

.logo-strip.red {
  background: crimson;
}

.card-body.red {
  background: indianred;
}

.word>a>p {
  color: black;
  text-transform: uppercase;
  font-family: "Arial Black", Gadget, sans-serif;
}